import React from 'react';
import { Link } from 'gatsby';
import wwLogo from '../../images/large-ww-logo.png';
import { Dialog } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './Navigation.scss';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: 'top',
      open: false,
    };
  }

  componentDidMount() {
    this.listener = document.addEventListener('scroll', (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (this.state.status !== 'amir') {
          this.setState({ status: 'amir' });
        }
      } else {
        if (this.state.status !== 'top') {
          this.setState({ status: 'top' });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener('scroll', this.listener);
  }

  render() {
    const handleClickOpen = () => {
      this.setState({
        open: true,
      });
    };

    const handleClose = () => {
      this.setState({
        open: false,
      });
    };

    const ListLink = (props) => (
      <li className="navigation__link-item" onClick={handleClose}>
        <AnchorLink to={props.to} title={props.children}>
          {props.children}
        </AnchorLink>
      </li>
    );

    return (
      <>
        <header
          className={`navigation ${this.state.status === 'top' ? '' : 'navigation--scrolled'}`}
          style={{
            backgroundColor: this.state.status === 'top' ? 'transparent' : 'white',
            boxShadow: this.state.status === 'top' ? 'none' : '3px 4px 8px rgba(0,0,0, 0.15)',
          }}
        >
          <div className="navigation__wrapper">
            <Link to="/" className="navigation__logo" aria-label="Home">
              <img src={wwLogo} alt="W&amp;W Asset Managers" />
            </Link>
            <ul className="navigation__links">
              <ListLink to="/#about">About Us</ListLink>
              <ListLink to="/#services">Our Services</ListLink>
              <ListLink to="/#team">Our Team</ListLink>
              <ListLink to="/#contact">Contact Us</ListLink>
            </ul>
            <button className="navigation__menu-toggle" onClick={handleClickOpen}>
              <MenuIcon className="navigation__menu-toggle__icon" fontSize="large" />
            </button>
          </div>
        </header>
        <Dialog fullScreen open={this.state.open} onClose={handleClose}>
          <div className="navigation__dialog">
            <div className="navigation__wrapper">
              <Link to="/" className="navigation__logo" aria-label="Home">
                <img src={wwLogo} alt="W&amp;W Investment Managers" />
              </Link>
              <button className="navigation__menu-toggle" onClick={handleClose}>
                <CloseIcon className="navigation__menu-toggle__icon" fontSize="large" />
              </button>
            </div>
            <div className="navigation__dialog-links">
              <ul className="navigation__dialog-links-list">
                <ListLink to="/#about">About Us</ListLink>
                <ListLink to="/#services">Our Services</ListLink>
                <ListLink to="/#team">Our Team</ListLink>
                <ListLink to="/#contact">Contact Us</ListLink>
              </ul>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}
