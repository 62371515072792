import React from 'react';
import Fade from 'react-reveal/Fade';
import './LandingBanner.scss';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImg from 'gatsby-background-image';

const LandingBanner = ({ title, alt }) => {
  const data = useStaticQuery(graphql`
    query imagesandImages {
      image: file(relativePath: { eq: "mountains.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid(quality: 100, webpQuality: 100, maxWidth: 2048) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="landing" className={`landing-banner ${alt ? 'landing-banner--alt' : ''}`}>
      <BackgroundImg
        className="landing-banner__background"
        fluid={data.image.childImageSharp.fluid}
        alt="5 Earlsfort Terrace, image courtesy of IPUT"
        draggable={false}
        critical
      ></BackgroundImg>
      <div className="landing-banner__gradient"></div>
      <Fade left>
        <div className="landing-banner__container">
          <div className="landing-banner__text-wrapper">
            <p className="landing-banner__title">W&amp;W Asset Management Ireland DAC</p>
            <h1 className="landing-banner__slogan">{title}</h1>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default LandingBanner;
