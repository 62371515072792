import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__links-container">
        <div className="footer__navigation-links">
          <p className="footer__navigation">Navigation</p>
          <div className="footer__links-wrapper">
            <AnchorLink className="footer__terms-link" to="/#about" title="About Us">
              About Us
            </AnchorLink>
            <AnchorLink className="footer__terms-link" to="/#services" title="Our Services">
              Our Services
            </AnchorLink>
            <AnchorLink className="footer__terms-link" to="/#team" title="Our Team">
              Our Team
            </AnchorLink>
            <AnchorLink className="footer__terms-link" to="/#contact" title="Contact Us">
              Contact Us
            </AnchorLink>
          </div>
        </div>
        <div className="footer__additional-information-links">
          <p className="footer__additional-information">Additional Information</p>
          <div className="footer__links-wrapper">
            <a className="footer__terms-link" id="cookie-management-link" href="#">
              Cookie Management
            </a>
            <Link className="footer__terms-link" to="/terms-and-conditions">
              Terms
            </Link>
            <Link className="footer__terms-link" to="/remuneration-policy">
              Remuneration Policy
            </Link>
            <Link className="footer__terms-link" to="/shareholder-engagement-policy">
              Shareholder Engagement Policy
            </Link>
            <Link
              className="footer__terms-link"
              to="/sustainable-finance-disclosure-regulation-policy"
            >
              Sustainable Finance Disclosure Regulation Policy
            </Link>
            <Link className="footer__terms-link" to="/cookie-policy">
              Cookie Policy
            </Link>
            <Link className="footer__terms-link" to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className="footer__terms-link" to="/whistleblowing-policy">
              Whistleblowing Policy
            </Link>
            <Link className="footer__terms-link" to="/impressum">
              Impressum
            </Link>
          </div>
        </div>
        <p className="footer__copyright">
          © {new Date().getFullYear()}, W&amp;W Asset Management Ireland DAC
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
